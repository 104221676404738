export enum Gender {
    Male = "male",
    Female = "female",
    Unknown = "unknown"
}

export enum UserType {
    Driver,
    Rider,
    Admin
}

export enum Status{
    Open = "Open",
    Closed = "Closed",
    Resolved = "Resolved"
}